import React from 'react';
import ContextWrapper from '@components/ContextWrapper';

import 'swiper/swiper-bundle.css';
import './src/styles/global.css';
import './src/styles/fonts.css';

export const wrapPageElement = ({ element, props }) => <ContextWrapper>{element}</ContextWrapper>;

console.log(`
  .................................................................................
  ...oooooooooooo.............................-/-......................:+o+/-......
  ...MMMMMMMMMMMN............................./MNds/-...............-sNMMMMMNh:....
  ...MMMMMMMMMMMN........:MMMMMMMMMMMd......../MMMMMMds/-...........mMMMMMMMMMN:...
  ...MMMMMMMMMMMN........:MMMMMMMMMMMd......../MMMMMMMMMNy-......../MMMMMMMMMMMo...
  ...MMMMMMMMMMMN........:MMMMMMMMMMMd......../MMMMMMmy+-...........mMMMMMMMMMN:...
  ...MMMMMMMMMMMN............................./MMmy+-...............-yNMMMMMMh:....
  ...ssssssssssso.............................-/-....................../+oo/-......
  .................................................................................

  Hey there! We're always on the hunt for the curious, drop us a line at talent@finally.global!
`)
