import React, { createContext, useState } from 'react';

export interface IContext {
  menuOpen: boolean;
  contactModalOpen: boolean;
  headerColor?: string;
  headerIconColor?: string;
  toggleMenu: () => void;
  toggleContactModal: () => void;
  updateHeaderColor: (color?: string) => void;
  updateHeaderIconColor: (color?: string) => void;
}

const defaultState = { menuOpen: false, contactModalOpen: false };

export const Context = createContext<IContext>(defaultState);

const ContextWrapper: React.FC = ({ children }) => {
  const [headerColor, setHeaderColor] = useState<string>();
  const [headerIconColor, setHeaderIconColor] = useState<string>();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [contactModalOpen, setContactModalOpen] = useState<boolean>(false);

  const toggleMenu = (): void => setMenuOpen((v) => !v);
  const toggleContactModal = (): void => setContactModalOpen((v) => !v);
  const updateHeaderColor = (color?: string) => setHeaderColor(color);
  const updateHeaderIconColor = (color?: string) => setHeaderIconColor(color);

  const value = {
    menuOpen,
    contactModalOpen,
    headerColor,
    headerIconColor,
    toggleMenu,
    toggleContactModal,
    updateHeaderColor,
    updateHeaderIconColor,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default ContextWrapper;
